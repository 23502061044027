/* xs */
:root {
    --small-marge: 1.5rem;
    --medium-marge: 1.5rem;

    --z-index-background: 1;
    --z-index-content: 2;
    --z-index-navbar-container: 3;

    --left-panel-full-width: 10rem;
    --left-panel-short-width: 3rem;
    --left-panel-auto-width: var(--left-panel-short-width);

    --layout-inner-gap: calc(1rem + 1vw);
}

/* sm */
@media (min-width: 576px) {
    :root {
        --small-marge: 1.5rem;
        --medium-marge: 2rem;

        --left-panel-auto-width: var(--left-panel-full-width);
    }
}

/* md */
@media (min-width: 768px) {
    :root {
        --small-marge: 1.5rem;
        --medium-marge: 2.5rem;
    }
}

/* lg */
@media (min-width: 992px) {
    :root {
        --small-marge: 1.5rem;
        --medium-marge: 3rem;
    }
}

/* xl */
@media (min-width: 1200px) {
    :root {
        --small-marge: 1.5rem;
        --medium-marge: 3.5rem;
    }
}

/* xll */
@media (min-width: 1400px) {
    :root {
        --small-marge: 1.5rem;
        --medium-marge: 4rem;
    }
}

.border-circle {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    line-height: 32px;
    background: transparent;
    text-align: center;
    border: 2px solid currentColor;
    color: inherit;
}

.vertical-line {
    border-left: 3px dotted currentColor;
    height: 100%;
    width: 3px;
    margin-left: auto;
    margin-right: auto;
}

.cards-container-5, .cards-container-4, .cards-container-3, .cards-container-2, .cards-container-1 {
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-columns: 1fr;
}

.two-columns-into-two-rows {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.two-columns-into-two-rows-reversed {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
}

.two-columns {
    display: grid;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr;
}
.two-columns-first-column .two-columns-first-column-reversed {
    grid-column: 1;
    text-align: left;
}
.two-columns-second-column .two-columns-second-column-reversed {
    grid-column: 1;
    text-align: left;
}

.news-columns {
    display: grid;
    grid-row-gap: 1rem;
    grid-template-columns: auto;
}

.rtl-lg {
    direction: initial;
}

.footer-without-contacts-grid {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-row: 1fr;
}

.footer-with-contacts-grid {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-row: 1fr;
}
.footer-with-contacts-grid-first-child {
    grid-column: auto;
}

.grid-dense {
    grid-auto-flow: dense;
}

/* sm */
@media (min-width: 576px) {
    .cards-container-5, .cards-container-4, .cards-container-2 {
        grid-template-columns: 1fr 1fr;
    }
}

/* md */
@media (min-width: 768px) {
    .cards-container-5 {
        grid-template-columns: 1fr 1fr;
    }
    .cards-container-4 {
        grid-template-columns: 1fr 1fr;
    }

    .footer-with-contacts-grid {
        grid-template-columns: 1fr auto;
    }
    .footer-with-contacts-grid-first-child {
        grid-column: 1/3;
    }
}

/* lg */
@media (min-width: 992px) {
    .cards-container-5 {
        grid-template-columns: 1fr 1fr;
    }
    .cards-container-4 {
        grid-template-columns: 1fr 1fr;
    }
    .cards-container-3 {
        grid-template-columns: 1fr 1fr;
    }

    .two-columns {
        display: grid;
        grid-row-gap: 1rem;
        grid-template-columns: 1fr 1fr;
    }
    .two-columns-first-column {
        grid-column: 1;
        text-align: right;
    }
    .two-columns-first-column-reversed {
        grid-column: 2;
        text-align: left;
    }
    .two-columns-second-column {
        grid-column: 2;
        text-align: left;
    }
    .two-columns-second-column-reversed {
        grid-column: 1;
        text-align: right;
    }

    .two-columns-into-two-rows {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .two-columns-into-two-rows-reversed {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .news-columns {
        display: grid;
        grid-row-gap: 1rem;
        grid-template-columns: 200px 1fr;
    }
    .rtl-lg {
        direction: rtl;
    }

    .footer-without-contacts-grid {
        grid-template-columns: 1fr auto;
    }
}

/* xl */
@media (min-width: 1200px) {
    .cards-container-5 {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .cards-container-4 {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .cards-container-3 {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .footer-with-contacts-grid {
        grid-template-columns: auto auto 1fr;
    }

    .footer-with-contacts-grid-first-child {
        grid-column: auto;
    }
}

/* xll */
@media (min-width: 1400px) {
    .cards-container-5 {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    .cards-container-4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .gallery-image {
        width: 576px;
        height: 370px;
    }
    .lp-image {
        max-width: 576px;
        max-height: 370px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .gallery-image {
        width: 768px;
        height: 490px;
    }
    .lp-image {
        max-width: 768px;
        max-height: 490px;
    }
}

@media screen and (min-width: 920px) {
    .gallery-image {
        width: 920px;
        height: 590px;
    }
    .lp-image {
        max-width: 920px;
        max-height: 590px;
    }
}

.hidden {
    visibility: hidden;
}

.w-0 {
    width: 0;
}

.right-0 {
    right: 0
}

.button-min-width {
    min-width: 7rem
}

@keyframes hide-animation {
    0% { opacity: 1; }
    50% { opacity: 1; }
    100% {opacity: 0;}
}

.hide-animation {
    opacity: 0;
    animation-name: hide-animation;
    animation-duration: 2s;
    animation-iteration-count: 1;
}

.text-border {
    text-shadow: 0 0 2px #000; /* horizontal-offset vertical-offset 'blur' colour */
    -moz-text-shadow: 0 0 2px #000;
    -webkit-text-shadow: 0 0 2px #000;
}

.button-circle {
    border: 1px solid gray !important;
    border-radius: 50% !important;
    display: flex !important;
    height: 100% !important;
    aspect-ratio: 1/1;

}

button-circle::after {
    content: "" !important;
    display: block !important;
    padding-bottom: 100% !important;
}

.polaroid-container {
    position: relative;
    display: grid;
}

.polaroid {
    background: #fff;
    padding: 1rem;
    box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
    z-index: 0;

    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    top: 0;
}

.polaroid > img {
    max-width: 100%;
    height: auto;
}

.polaroid-caption {
    font-size: 1.8rem;
    text-align: center;
    line-height: 2em;
}

.polaroid:before {
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.35s;
}

.polaroid-1 {
    transform: scale(0.5, 0.5) rotate(-5deg) translate(-33%, -33%);
    transition: all 0.35s;
}

.polaroid-2 {
    transform: scale(0.5, 0.5) rotate(5deg) translate(33%, -33%);
    transition: all 0.35s;
}

.polaroid-3 {
    transform: scale(0.5, 0.5) rotate(-10deg) translate(-40%, 33%);
    transition: all 0.35s;
}

.polaroid-4 {
    transform: scale(0.5, 0.5) rotate(2deg) translate(40%, 40%);
    transition: all 0.35s;
}

.polaroid-5 {
    transform: scale(0.5, 0.5) rotate(-2deg) translate(0, 0);
    transition: all 0.35s;
}

.polaroid:hover {
    transform: scale(1, 1) rotate(0) !important;
    transition: all 0.35s;
    z-index: 1;
}

.lp-top-toolbar-preview {
    background-color: inherit;
    color: black;
    width: 100%;
    position: relative;
}

.lp-top-toolbar {
    background-color: inherit;
    transition: background-color .35s linear;
    color: black;
    width: 100%;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1020;
}

    .lp-top-toolbar.scrolled {
        background-color: white !important;
        transition: background-color .35s linear;
        box-shadow: 0px 0.625rem 1rem rgba(35, 35, 35, 0.1);
    }

.lp-toolbar-other-element {
}

.lp-top-toolbar.scrolled .lp-toolbar-link {
    color: black !important;
    transition: color .35s linear;
    text-shadow: initial !important;
}
.lp-top-toolbar.scrolled .lp-toolbar-link-right {
    color: black !important;
    transition: color .35s linear;
    text-shadow: initial !important;
}
.lp-top-toolbar.scrolled .bi-list {
    color: black !important;
    transition: color .35s linear;
    text-shadow: initial !important;
}
.lp-top-toolbar.scrolled .lp-toolbar-other-element {
    color: black !important;
    transition: color .35s linear;
    text-shadow: initial !important;
}

.custom-accordion-header .accordion-button {
    background: transparent !important;
    box-shadow: none !important;
    border-color: rgba(0,0,0,.125) !important;
}

.top-1 {
    top: 0.25rem;
}
.top-2 {
    top: 0.5rem;
}
.top-3 {
    top: 1rem;
}
.top-4 {
    top: 1.5rem;
}
.top-5 {
    top: 3rem;
}

.left-0 {
    left: 0;
}
.left-1 {
    left: 0.25rem;
}
.left-2 {
    left: 0.5rem;
}
.left-3 {
    left: 1rem;
}
.left-4 {
    left: 1.5rem;
}
.left-5 {
    left: 3rem;
}

.bottom-1 {
    bottom: 0.25rem;
}
.bottom-2 {
    bottom: 0.5rem;
}
.bottom-3 {
    bottom: 1rem;
}
.bottom-4 {
    bottom: 1.5rem;
}
.bottom-5 {
    bottom: 3rem;
}

.right-1 {
    right: 0.25rem;
}
.right-2 {
    right: 0.5rem;
}
.right-3 {
    right: 1rem;
}
.right-4 {
    right: 1.5rem;
}
.right-5 {
    right: 3rem;
}

.height-to-weight {
    aspect-ratio: 1/1;
    width: 100%;
}

.doodle-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    display: flex;
}

.effect-appear {
}

.effect-appear-unhide {
    opacity: 0;
    transition: 1s all ease;
}
    .effect-appear-unhide.active {
        opacity: initial;
    }

.effect-appear-bounceIn {
    opacity: 0;
    transform: scale(.3);
    transition: 1s all ease;
}
    .effect-appear-bounceIn.active {
        opacity: initial;
        transform: initial;
    }

.effect-appear-grayscale {
    filter: grayscale(100%);
    transition: 1s all ease;
}
    .effect-appear-grayscale.active {
        filter: initial;
    }

.normal-text-wrap {
    word-wrap: normal !important;
}

.darker-on-hover {
    -webkit-filter: brightness(100%);
    -moz-filter: brightness(100%);
    -o-filter: brightness(100%);
    -ms-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transition: all .15s ease;
    -moz-transition: all .15s ease;
    -o-transition: all .15s ease;
    -ms-transition: all .15s ease;
    transition: all .15s ease;
}

    .darker-on-hover:hover {
        -webkit-filter: brightness(90%);
        -moz-filter: brightness(90%);
        -o-filter: brightness(90%);
        -ms-filter: brightness(90%);
        filter: brightness(90%);
        -webkit-transition: all .15s ease;
        -moz-transition: all .15s ease;
        -o-transition: all .15s ease;
        -ms-transition: all .15s ease;
        transition: all .15s ease;
    }

.mb-negative {
    margin-bottom: -0.49px
}

.maxwidth-100 {
    max-width: 100%;
}

.text-align-center {
    text-align: center;
}

.opacity-60 {
    opacity: 0.6 !important;
}

.cursor-pointer {
    cursor: pointer
}

.turn-animation-off {
    transition-duration: 0.3s;
    transition-property: transform;
}
.turn-animation-on {
    transition-duration: 0.3s;
    transition-property: transform;
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
}
.no-after:after {
    display: none !important;
}