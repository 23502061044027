.lp-root-container {
    background: white;
}

.lp-text {
    color: #3b505b;
    font-family: Roboto, Sans-Serif;
    font-size: calc(0.9rem + .3vw) !important;
    line-height: 1.75 !important;
    margin-top: 0;
    overflow-wrap: anywhere;
    width: 100%;
}

    .lp-text.tisona {
        font-family: Geologica,Roboto,Sans-Serif;
    }

        .lp-text.tisona.small-card-content > p {
            margin-bottom: 0;
        }

        .lp-text.tisona > ul {
            list-style: none; 
            padding: 0; 
            margin: 0; 
        }
            .lp-text.tisona > ul li {
                position: relative;
                padding-left: 20px;
                margin-bottom: 10px;
            }

                .lp-text.tisona > ul li::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: calc(.9rem + .3vw - 7px);
                    width: 10px;
                    height: 10px;
                    background-color: green;
                }

.lp-text-light {
    color: #bbbbbb;
    font-family: Roboto, Sans-Serif;
    font-size: calc(0.9rem + .3vw) !important;
    line-height: 1.75 !important;
    margin-top: 0;
    overflow-wrap: anywhere;
}

    .lp-text-light.tisona {
        font-family: Geologica,Roboto,Sans-Serif;
    }

.lp-text-medium-light {
    color: #999999;
    font-family: Roboto, Sans-Serif;
    font-size: calc(0.9rem + .3vw) !important;
    line-height: 1.75 !important;
    margin-top: 0;
    overflow-wrap: anywhere;
}

    .lp-text-medium-light.tisona {
        font-family: Geologica,Roboto,Sans-Serif;
    }

.lp-text-link {
    font-family: Roboto, Sans-Serif;
    font-size: calc(0.9rem + .3vw) !important;
    line-height: 1.75 !important;
    margin-top: 0;
    overflow-wrap: anywhere;
    width: 100%;
}

    .lp-text-link.tisona {
        font-family: Geologica,Roboto,Sans-Serif;
    }

.lp-text-after-subtitle {
    color: #3b505b;
    font-family: Roboto,Sans-Serif;
    font-size: calc(0.9rem + .3vw) !important;
    line-height: 1.75 !important;
    overflow-wrap: anywhere;
    width: 100%;
}

    .lp-text-after-subtitle.tisona {
        font-family: Geologica,Roboto,Sans-Serif;
    }

.lp-text-pagetitle {
    color: #173647;
    font-family: Roboto,sans-serif;
    font-size: calc(2.7rem + 1.5vw) !important;
    line-height: 1.25 !important;
    overflow-wrap: anywhere;
    width: 100%;
}

    .lp-text-pagetitle.tisona {
        font-family: Geologica,Roboto,Sans-Serif;
    }

.lp-text-title {
    color: #173647;
    font-family: Roboto, sans-serif;
    font-size: calc(1.6rem + .8vw) !important;
    line-height: 1.25 !important;
    letter-spacing: .01em !important;
    overflow-wrap: anywhere;
    width: 100%;
}

    .lp-text-title.tisona {
        font-family: Geologica,Roboto,Sans-Serif;
        font-weight: 400;
        color: #004278;
    }

.lp-text-subtitle {
    color: #173647;
    font-family: Roboto,sans-serif;
    font-size: calc(1.1rem + .7vw) !important;
    line-height: 1.25 !important;
    letter-spacing: .005em !important;
    overflow-wrap: anywhere;
    width: 100%;
}

    .lp-text-subtitle.tisona {
        font-family: Geologica,Roboto,Sans-Serif;
    }

.lp-text-big {
    color: #173647;
    font-family: Roboto,sans-serif;
    font-size: calc(1.1rem + .7vw) !important;
    line-height: 1.75 !important;
    letter-spacing: .005em !important;
    overflow-wrap: anywhere;
    width: 100%;
}

    .lp-text-big.tisona {
        font-family: Geologica,Roboto,Sans-Serif;
    }

.lp-text-small {
    color: #173647;
    font-family: montserrat;
    font-size: calc(0.9rem + .1vw) !important;
    line-height: 1.75 !important;
    overflow-wrap: anywhere;
    width: 100%;
}

.lp-text-small p {
    margin-bottom: 0;
}

.lp-text-description {
    color: gray;
    font-family: Roboto,sans-serif;
    font-size: calc(1.1rem + .3vw) !important;
    line-height: 1.25 !important;
    letter-spacing: .005em !important;
    overflow-wrap: anywhere;
    width: 100%;
}

    .lp-text-description.tisona {
        font-family: Geologica,Roboto,Sans-Serif;
    }

.lp-text-date {
    color: gray;
    font-family: inherit;
    font-size: calc(1.1rem + .1vw) !important;
    line-height: 1.75 !important;
    letter-spacing: .005em !important;
    overflow-wrap: anywhere;
    width: 100%;
}

.lp-text-uppertitle {
    color: #173647;
    letter-spacing: 1.5px !important;
    font-family: Roboto,sans-serif;
    font-size: calc(0.8rem + .4vw) !important;
    line-height: 1.25 !important;
    overflow-wrap: anywhere;
    width: 100%;
}

    .lp-text-uppertitle.tisona {
        font-family: Geologica,Roboto,Sans-Serif;
    }

.lp-toolbar-link {
    margin: 0 0 0 1rem !important;
    padding: 0 !important;
    text-decoration: none;
    color: inherit;
    font-family: Roboto,sans-serif;
}

    .lp-toolbar-link.tisona {
        font-family: Geologica,Roboto,Sans-Serif;
    }

.lp-toolbar-link-right {
    margin: 0 1rem 0 0 !important;
    padding: 0 !important;
    text-decoration: none;
    color: inherit;
    font-family: Roboto,sans-serif;
}

    .lp-toolbar-link-right.tisona {
        font-family: Geologica,Roboto,Sans-Serif;
    }

.lp-icon-middle {
    font-size: 1.25em;
}

.last-without-margin > :last-child {
    margin-bottom: 0;
}
.all-without-margin > p {
    margin-bottom: 0;
}