.main-left-sidebar {
    background-color: #313a46 !important;
}

.border-radius-0 {
    border-radius: 0 !important;
}

.left-sidebar {
    background-color: #EEEEEE !important;
}

.btn-color {
    color: var(--bs-btn-color) !important;
}

.general-content-container {
    background-color: #EEEEEE !important;
}

.card-shadow {
    box-shadow: 0 0.1875rem 0.1875rem 0 rgba(14,34,56,.07);
}

.dropdown-arrow-hide .dropdown-toggle::after {
    display: none !important;
}

.back-icon {
    color: #173647 !important;
    font-size: 1.5rem !important;
    text-decoration: none !important;
    line-height: 0 !important;
    font-family: Roboto,sans-serif !important;
    padding: 0 !important;
}